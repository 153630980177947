body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body.toastr-confirm-active {
  overflow: hidden;
}

.redux-toastr *,
.redux-toastr *:before,
.redux-toastr *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
}

.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center {
  top: 0;
}

.redux-toastr .top-right,
.redux-toastr .bottom-right {
  right: 0;
}

.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  bottom: 0;
}

.redux-toastr .top-left,
.redux-toastr .bottom-left {
  left: 0;
}

.redux-toastr .top-center,
.redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px;
}

@media (max-width: 320px) {
  .redux-toastr .top-left,
  .redux-toastr .top-right,
  .redux-toastr .top-center,
  .redux-toastr .bottom-left,
  .redux-toastr .bottom-right,
  .redux-toastr .bottom-center {
    width: 320px;
  }
  .redux-toastr .top-center,
  .redux-toastr .bottom-center {
    margin-left: -160px;
  }
}

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: 0.94;
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

.redux-toastr .toastr:hover:not(.rrt-message) {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.redux-toastr .toastr .toastr-status {
  width: 100%;
  height: 5px;
}

.redux-toastr .toastr .toastr-status.success {
  background-color: #60bb71;
}

.redux-toastr .toastr .toastr-status.warning {
  background-color: #f7a336;
}

.redux-toastr .toastr .toastr-status.info {
  background-color: #58abc3;
}

.redux-toastr .toastr .toastr-status.error {
  background-color: #db6a64;
}

.redux-toastr .toastr .rrt-left-container,
.redux-toastr .toastr .rrt-right-container {
  float: left;
  text-align: center;
  overflow: hidden;
}

.redux-toastr .toastr .rrt-left-container {
  width: 80px;
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
  left: 5px;
  line-height: 60px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: white;
  vertical-align: middle;
  margin-top: 5px;
}

.redux-toastr .toastr .rrt-middle-container {
  width: 65%;
  margin-left: 80px;
  position: relative;
  float: left;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  font-size: 1em;
  text-align: left;
  padding: 10px 5px;
}

.redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
}

.redux-toastr .toastr .rrt-right-container {
  width: 10%;
}

.redux-toastr .toastr .close-toastr {
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  font-size: 22px;
  border: none;
  outline: none;
  opacity: 0.5;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
  color: #000;
}

.redux-toastr .toastr .close-toastr:hover {
  opacity: 1;
}

.redux-toastr .toastr .close-toastr:focus {
  outline: none;
}

.redux-toastr .toastr .close-toastr span {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.redux-toastr .toastr.rrt-info,
.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-warning,
.redux-toastr .toastr.rrt-error {
  color: white;
}

.redux-toastr .toastr.rrt-info {
  background-color: #58abc3;
}

.redux-toastr .toastr.rrt-info .rrt-progressbar {
  background-color: #378298;
}

.redux-toastr .toastr.rrt-success {
  background-color: #60bb71;
}

.redux-toastr .toastr.rrt-success .rrt-progressbar {
  background-color: #3e914d;
}

.redux-toastr .toastr.rrt-warning {
  background-color: #f7a336;
}

.redux-toastr .toastr.rrt-warning .rrt-progressbar {
  background-color: #d87e09;
}

.redux-toastr .toastr.rrt-error {
  background-color: #db6a64;
}

.redux-toastr .toastr.rrt-error .rrt-progressbar {
  background-color: #c5352e;
}

.redux-toastr .toastr.rrt-light .rrt-progressbar {
  background-color: #ccc;
}

.redux-toastr .toastr.rrt-light .toastr-icon {
  fill: #333 !important;
}

.redux-toastr .toastr.rrt-message {
  opacity: 1;
  border: 1px solid #dbdbdb;
}

.redux-toastr .toastr.rrt-message .rrt-title {
  width: 90%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-size: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 50px;
  padding: 0 20px;
}

.redux-toastr .toastr.rrt-message .rrt-text {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  padding: 15px;
  font-size: 1.1em;
  margin-bottom: 20px;
}
.react-autosuggest__suggestions-container {
  margin-top: 5px;
  background-color: #d3d3d3;
  max-height: 200px;
  overflow-y: auto;
  width: 108%;
}
.itemStyle {
  border-bottom: 1px solid #ddd !important;
}

.redux-toastr .toastr.rrt-message .rrt-text img {
  display: block;
  margin: 10px auto;
  max-width: 100%;
}

.redux-toastr .toastr.rrt-message .close-toastr {
  height: 50px;
}

.redux-toastr .toastr .rrt-progress-container {
  height: 5px;
  margin: 0 -20px -20px -60px;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
  border-radius: 0 0 0 4px;
  height: 100%;
}

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.calendar .react-autosuggest__suggestions-container {
  margin-top: 5px;
  background-color: #d3d3d3;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}
